import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { logout } from "@mainaction";
import { IDLE_TIME } from "@constant";
// core components
import Header from "./header";
import Sidebar from "./sidebar";
import MainContainer from "./main/index";
import routes from "../routes";

const Admin = (props) => {
  var { isLoggedIn, session } = props;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn) {
      props.logout(session);
      navigate("/auth/login" + location?.search);
    } else if (isLoggedIn && !session?.username) {
      navigate("/auth/signupuser" + location?.search);
    }
    if (isLoggedIn) {
      if (location.pathname === "/") {
        navigate(session?.userid === 1 ? "/classes" : "/dashboard" + location?.search);
      } else {
        // checkking mq teacher or student hide side menu
        var hideSideMenu = [];
        if (session?.mqstudent === true) {
          hideSideMenu = ["/messages"];
        }
        if (session?.userid === 1) {
          hideSideMenu = [
            "/dashboard",
            "/students",
            "/questionsets",
            "/reports",
            "/messages",
          ];
        }

        if (hideSideMenu.includes(location.pathname)) {
          navigate(session?.userid === 1 ? "/classes" : "/dashboard" + location?.search);
        }
      }
    }
  }, [isLoggedIn, session]);

  const onIdle = () => {
    // Do some idle action like log out your user
    props.logout(session);
  };

  const { isIdle } = useIdleTimer({ onIdle, timeout: IDLE_TIME });

  return isLoggedIn ? (
    <>
      {/* <Header routes={routes} />
      <div className="h-full">
        <div className="h-full lg:grid lg:grid-cols-12">
          <div className="py-4 px-4 border-r-1 bg-gray-100 hidden lg:block lg:col-span-3 xl:col-span-2">
            <Sidebar routes={routes} />
          </div>
          <main className="py-4 h-5/6 sm:h-90% lg:col-span-9 xl:col-span-10 overflow-y-auto">
            <MainContainer routes={routes} />
          </main>
        </div>
      </div> */}
      <div className="h-full">
        <div className="h-full">
          <div className="hidden lg:flex lg:w-65 lg:flex-col lg:fixed lg:inset-y-0 z-[1000]">
            <Sidebar routes={routes} />
          </div>
          <main className="h-full lg:pl-65 flex flex-col flex-1 overflow-x-hidden">
            <MainContainer routes={routes} />
          </main>
        </div>
      </div>
    </>
  ) : (
    ""
  );
};

const mapStateToProps = (state) => {
  return { isLoggedIn: state.isLoggedIn, session: state.session };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
